import { Component, Mixins } from "vue-property-decorator";

import TeamMixin from "../../common/mixins/team.mixin";

@Component({
  components: {
    TeamMemberActionsMenu: () =>
      import("./components/TeamMemberActionsMenu/TeamMemberActionsMenu.vue"),
    WalletTransferFundsMenu: () =>
      import(
        "./components/WalletTransferFundsMenu/WalletTransferFundsMenu.vue"
      ),
    CardIssueLimitMenu: () =>
      import("./components/CardIssueLimitMenu/CardIssueLimitMenu.vue"),
    AutoRefillMenu: () =>
      import("./components/AutoRefillMenu/AutoRefillMenu.vue"),
  },
})
export default class DesktopTeam extends Mixins(TeamMixin) {}
