var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('DesktopScreen',{staticClass:"team",attrs:{"loading":_vm.loading}},[(_vm.canViewTeamPage)?[_c('div',{staticClass:"d-flex align-center mb-4"},[(_vm.canViewInviteUrl)?_c('div',{staticClass:"d-flex align-center",attrs:{"id":_vm.htmlElementId.teamInviteUrlField}},[_c('v-icon',{staticClass:"flex-shrink-0 mr-2",attrs:{"size":"24","color":"#243143"}},[_vm._v(" $userBusiness ")]),_c('span',{staticClass:"text-caption-2 text-truncate font-weight-bold blue-grey--text text--darken-4 flex-shrink-1"},[_vm._v(" "+_vm._s(_vm.inviteUrl)+" ")]),_c('v-btn',{staticClass:"flex-shrink-0 ml-4",attrs:{"color":"#ACB8C9","icon":"","small":!_vm.$vuetify.breakpoint.xlOnly,"disabled":!_vm.inviteUrl},on:{"click":_vm.copyLinkClipboard}},[_c('v-icon',{attrs:{"size":_vm.$vuetify.breakpoint.xlOnly ? 24 : 20}},[_vm._v(" $copy ")])],1),_c('v-btn',{staticClass:"flex-shrink-0 mr-4",attrs:{"color":"#ACB8C9","icon":"","small":!_vm.$vuetify.breakpoint.xlOnly,"loading":_vm.inviteLink.loading},on:{"click":function($event){return _vm.fetchTeamInvite({ refresh: true })}}},[_c('v-icon',{attrs:{"size":_vm.$vuetify.breakpoint.xlOnly ? 24 : 20}},[_vm._v(" $refresh ")])],1)],1):_vm._e(),_c('TeamBalance',{attrs:{"balance":_vm.teamBalance}})],1),_c('FilterTableTopBar',{attrs:{"loading-search":_vm.isSearching,"table-header-items":_vm.topBarTableHeaderItems,"table-header-value":_vm.topBarTableHeaderValue,"loading":_vm.loading,"debounced":""},on:{"update:loadingSearch":function($event){_vm.isSearching=$event},"update:loading-search":function($event){_vm.isSearching=$event},"refresh":_vm.refreshTeamMembers,"change:table-header-value":_vm.onChangeTableHeaderValue},model:{value:(_vm.searchStr),callback:function ($$v) {_vm.searchStr=$$v},expression:"searchStr"}}),_c('v-data-table',{staticClass:"team__table hidden-progressbar",attrs:{"items":_vm.items,"headers":_vm.tableHeaders,"loading":_vm.loading,"footer-props":{
        itemsPerPageOptions: [5, 30, 45],
      },"items-per-page":_vm.itemsPerPage,"disable-pagination":"","hide-default-footer":""},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:`item.authority`,fn:function({ item: { authority } }){return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t( `$vuetify.dashboard.user.role.${authority.toLowerCase()}` ))+" ")]}},{key:`header.name`,fn:function({ header }){return [_c('span',{staticClass:"px-4"},[_vm._v(" "+_vm._s(header.text)+" ")])]}},{key:`item.enabled`,fn:function({ item }){return [_c('v-chip',{staticClass:"font-weight-medium",attrs:{"color":_vm.getTeamMemberStatus(item).color,"small":"","label":""}},[_vm._v(" "+_vm._s(_vm.getTeamMemberStatus(item).text)+" ")])]}},{key:`item.name`,fn:function({ item }){return [_c('div',{class:[
            'team__schema',
            {
              [`team__schema_nesting_${item.nestingLevel}`]:
                item.nestingLevel && item.flatIndex > 0,
              team__schema_top: item.nestingLevel && item.flatIndex === 0,
              'team__schema_first-child': item.memberIndex === 0,
              'team__schema_members-of-last-parent': item.membersOfLastParent,
            },
          ]},[_c('span',{staticClass:"team__schema-line"}),_c('span',{staticClass:"text-truncate text-caption-2 font-weight-bold d-block",style:({ maxWidth: '200px' })},[_vm._v(" "+_vm._s(_vm.getTeamMemberName(item))+" ")]),_c('div',[(_vm.editedTeamMember.email === item.email)?_c('v-text-field',{staticClass:"mt-0 text-caption-1",attrs:{"value":_vm.editedTeamMember.note,"autofocus":"","dense":"","hide-details":"","maxlength":"15"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.editTeamMember({ email: '', note: '' })},"blur":function($event){return _vm.editTeamMember({ email: '', note: '' })},"change":function($event){return _vm.onChangeTeamMemberNote({ note: $event, email: item.email })}}}):(_vm.canEditNote)?_c('v-btn',{staticClass:"text-caption-1 blue-grey--text text--darken-4 px-1 mt-1",attrs:{"min-width":0,"x-small":"","color":"rgba(36, 49, 67, 0.1)"},on:{"click":function($event){return _vm.editTeamMember({ email: item.email, note: item.note })}}},[(item.note)?_c('span',{staticClass:"d-inline-block mr-1"},[_vm._v(" "+_vm._s(item.note)+" ")]):_vm._e(),_c('v-icon',{attrs:{"size":"12","color":"#ACB8C9"}},[_vm._v(" $edit ")])],1):(item.note)?_c('span',{staticClass:"d-inline-block rounded text-caption-1 blue-grey--text text--darken-4 px-1 mt-1",style:({ backgroundColor: 'rgba(36, 49, 67, 0.1)' })},[_vm._v(" "+_vm._s(item.note)+" ")]):_vm._e()],1)])]}},{key:`item.cardBalance`,fn:function({ item }){return [_c('div',{staticClass:"text-no-wrap"},_vm._l((item.cardBalance),function(balance,currency,index){return _c('span',{key:currency},[_vm._v(" "+_vm._s(index > 0 ? "|" : "")+" "+_vm._s(_vm.formatMoney(balance, currency))+" ")])}),0)]}},{key:`item.numberOfActiveCards`,fn:function({ item }){return [_c('div',{class:[
            'team__menu-button-wrapper',
            {
              'team__menu-button-wrapper_support-hover':
                !_vm.usedMockData && _vm.checkCanSetLimit(item),
            },
          ],style:({ minWidth: '180px' })},[_c('div',{staticClass:"py-4 text-no-wrap team__menu-button-text"},[_vm._v(" "+_vm._s(item.numberOfActiveCards)+" | "+_vm._s(item.cardIssueLimit)+" ")]),(_vm.checkCanSetLimit(item))?_c('CardIssueLimitMenu',{attrs:{"email":item.email,"number-of-active-cards":item.numberOfActiveCards,"card-issue-limit":item.cardIssueLimit},on:{"success":_vm.onSuccessSetCardIssueLimit},scopedSlots:_vm._u([{key:"activator",fn:function({ attrs, on }){return [_c('v-btn',_vm._g(_vm._b({class:[
                  'ma-auto team__menu-button',
                  _vm.htmlElementClassName.teamCardIssueLimit,
                ],attrs:{"height":"40","width":"180","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.dashboard.set_limit"))+" "),_c('v-icon',{attrs:{"small":"","right":"","color":"rgba(255, 255, 255, 0.4)"}},[_vm._v(" $settings ")])],1)]}}],null,true)}):_vm._e()],1)]}},{key:`item.balance`,fn:function({ item }){return [_c('div',{class:[
            'team__menu-button-wrapper',
            {
              'team__menu-button-wrapper_support-hover':
                !_vm.usedMockData && _vm.checkCanTransferMoney(item),
            },
          ],style:({ minWidth: '150px' })},[_c('div',{staticClass:"py-4 text-no-wrap team__menu-button-text"},_vm._l((item.balance),function(balance,currency,index){return _c('span',{key:currency},[_vm._v(" "+_vm._s(index > 0 ? "|" : "")+" "+_vm._s(_vm.formatMoney(balance, currency))+" ")])}),0),(_vm.checkCanTransferMoney(item))?_c('WalletTransferFundsMenu',{attrs:{"disabled-reverse":!item.enabled,"sender":item.enabled ? _vm.profileEmailGetter : item.email,"sender-balance":item.enabled ? _vm.walletBalanceGetter : item.balance,"recipient":item.enabled ? item.email : _vm.profileEmailGetter,"recipient-balance":item.enabled ? item.balance : _vm.walletBalanceGetter,"sender-is-teamlead":item.enabled,"recipient-is-teamlead":!item.enabled},on:{"success":_vm.onSuccessTransferFunds},scopedSlots:_vm._u([{key:"activator",fn:function({ attrs, on }){return [_c('v-btn',_vm._g(_vm._b({class:[
                  'ma-auto team__menu-button',
                  _vm.htmlElementClassName.teamWalletTransferFunds,
                ],attrs:{"height":"40","width":"150","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.dashboard.transfer"))+" "),_c('v-icon',{attrs:{"small":"","right":"","color":"rgba(255, 255, 255, 0.4)"}},[_vm._v(" $creditCardB ")])],1)]}}],null,true)}):_vm._e()],1)]}},{key:`item.spend`,fn:function({ item }){return [_c('div',{staticClass:"text-no-wrap"},_vm._l((item.spend),function(balance,currency,index){return _c('span',{key:currency},[_vm._v(" "+_vm._s(index > 0 ? "|" : "")+" "+_vm._s(_vm.formatMoney(balance, currency))+" ")])}),0)]}},{key:`item.action`,fn:function({ item }){return [_c('div',{staticClass:"d-flex justify-end align-center"},[(_vm.checkCanAutoRefill(item))?_c('AutoRefillMenu',{staticClass:"flex-shrink-0 mr-2",attrs:{"email":item.email,"settings":item.autoRefillSettings,"currencies":item.autoRefillCurrencies,"balance":_vm.walletBalanceGetter},on:{"success":_vm.refreshTeamMembers}}):_vm._e(),(_vm.checkAccessTeamMemberActions(item))?_c('TeamMemberActionsMenu',{staticClass:"flex-shrink-0",attrs:{"team-member":item},on:{"delete:team-member":_vm.onConfirmDeleteTeamMember},scopedSlots:_vm._u([{key:"activator",fn:function({ attrs, on }){return [_c('v-btn',_vm._g(_vm._b({class:_vm.htmlElementClassName.teamActions,attrs:{"icon":"","color":"grey"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"24"}},[_vm._v(" $dotsVertical ")])],1)]}}],null,true)}):_vm._e()],1)]}}],null,true)}),_c('ConfirmDialog',{attrs:{"loading":_vm.confirmDialog.loading,"max-width":460},on:{"confirm":_vm.onConfirmDialog},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(
            _vm.$vuetify.lang.t(
              '$vuetify.team.sure_want_member',
              `<b>${_vm.confirmDialog.data.email}</b>`
            )
          )}})]},proxy:true}],null,false,2359587381),model:{value:(_vm.confirmDialog.showed),callback:function ($$v) {_vm.$set(_vm.confirmDialog, "showed", $$v)},expression:"confirmDialog.showed"}}),_c('Dialog',{attrs:{"max-width":"500"},model:{value:(_vm.isShowConfirmSecondFactorCode),callback:function ($$v) {_vm.isShowConfirmSecondFactorCode=$$v},expression:"isShowConfirmSecondFactorCode"}},[[_c('v-card',{staticClass:"pa-5"},[_c('v-form',{ref:"confirmSecondFactorForm",on:{"submit":function($event){$event.preventDefault();return _vm.confirmSecondFactor.apply(null, arguments)}}},[(_vm.profileSecondFactorEnabled)?_c('FormField',{attrs:{"required":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.fields.code_2fa"))+" ")]},proxy:true}],null,false,2097975761)},[_c('v-text-field',{attrs:{"rules":[_vm.fieldRules.required],"outlined":"","dense":""},model:{value:(_vm.editedTeamMember.secondFactorCode),callback:function ($$v) {_vm.$set(_vm.editedTeamMember, "secondFactorCode", $$v)},expression:"editedTeamMember.secondFactorCode"}})],1):_vm._e(),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"loading":_vm.loading,"block":"","type":"submit","color":"primary","height":"40"}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.dashboard.submit"))+" ")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"outlined":"","block":"","type":"button","color":"error","height":"40"},on:{"click":function($event){_vm.isShowConfirmSecondFactorCode = false}}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t("$vuetify.dashboard.close"))+" ")])],1)],1)],1)],1)]],2)]:_c('LockedPageBanner',{attrs:{"page-name":"team"}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }